/* You can add global styles to this file, and also import other style files */
@import "./kfzapp-theme.scss";
@import '~@angular/cdk/overlay-prebuilt.css';

.primary-color {
  color: $primary;
}

.primary-background-color {
  background-color: $primary;
}

.accent-color {
  color: $accent;
}

.accent-background-color {
  background-color: $accent;
}

.warn-color {
  color: $warn;
}

.warn-background-color {
  background-color: $warn;
}

body, html {
    height: 100%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 0px;
    background: white;  
}

.right-aligned-header > .mat-content {
  justify-content: space-between;
}

.mat-content > mat-panel-title, .mat-content > mat-panel-description {
flex: 0 0 auto;
}

.container-main {
    height: auto;
    width: 100%;
}

.container-table100 {
    width: 100%;
    min-height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 33px 30px;
    margin: 0px;
  }

  .wrap-main {
    width: 1170px;
  }

  .limiter {
    width: 100%;
    margin: 0 auto;
  }

  .wrap-table100 {
    width: 1170px;
  }
  
  @media (max-width: 576px) {
    .container-table100 {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  label {
    color: black;
  }

  .mat-card {
    padding-bottom: 0;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-right: 0.5em;
    margin-left: 0.5em;
  }

 .cdk-overlay-pane {
    width: auto !important;
}


.full-screen-dialog {
  min-width: 95vw;
  height: 90%;
}

/*
    CSS Styling für Tooltip
*/

.tooltip-verlauf-finished, .tooltip-verlauf-progress {
  font-size: 0.8em !important;
  white-space: pre-line;
}

.tooltip-verlauf-progress {
  background: #ff0000be !important;
}

.tooltip-verlauf-finished {
  background: green !important;
}

.nav-link-item {
  font-size: 14px !important;
}

/**
  CSS Styling für mat form fields
*/

mat-form-field .mat-form-field {
  &-underline {
    position: static;
  }
  &-subscript-wrapper {
    position: static;
  }
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.mat-button-focus-overlay {
  background-color: transparent!important;
}

*:focus {
  outline: 0 !important;
}

.user-dialog {
  width: 70% !important;
  max-width: 1000px !important; 
}