@use '../node_modules/@angular/material' as mat;
@import '../node_modules/@angular/material/theming'; 
@include mat.core();

/* For use in src/lib/core/theming/_palette.scss */
$kfzapp-primary: (
    50 : #e0f0fb,
    100 : #b3daf6,
    200 : #80c2f0,
    300 : #4daae9,
    400 : #2697e5,
    500 : #0085e0,
    600 : #007ddc,
    700 : #0072d8,
    800 : #0068d3,
    900 : #0055cb,
    A100 : #f3f7ff,
    A200 : #c0d6ff,
    A400 : #8db5ff,
    A700 : #74a5ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$kfzapp-accent: (
    50 : #f1eff8,
    100 : #ddd8ed,
    200 : #c7bee1,
    300 : #b0a3d5,
    400 : #9f90cc,
    500 : #8e7cc3,
    600 : #8674bd,
    700 : #7b69b5,
    800 : #715fae,
    900 : #5f4ca1,
    A100 : #fefeff,
    A200 : #d6cbff,
    A400 : #ae98ff,
    A700 : #9a7fff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$kfzapp-custom-primary: mat.define-palette($kfzapp-primary);
$kfzapp-custom-accent: mat.define-palette($kfzapp-accent);
$my-custom-warn: mat.define-palette(mat.$red-palette);

$my-custom-theme: mat.define-light-theme($kfzapp-custom-primary, $kfzapp-custom-accent, $my-custom-warn);

@include mat.all-component-themes($my-custom-theme);

$primary: mat.get-color-from-palette($kfzapp-custom-primary);
$accent: mat.get-color-from-palette($kfzapp-custom-accent);
$warn: mat.get-color-from-palette($my-custom-warn);

mat-nav-list a.active {
    font-weight: bold;
    color: $primary !important;
  }